<template>
    <div class="companyUsersIndex">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active> Employees</CBreadcrumbItem>
        </teleport>
        <CCard>
            <CCardHeader>
                <Navigation :items="tabsLink"/>
            </CCardHeader>

            <CCardBody>
                <div class="d-block d-sm-flex mb-3 flex-row align-items-center justify-content-start gap-4 toolbar">
                    <CInputGroup class="mb-2" :style="{ maxWidth: '300px' }">
                        <CInputGroupText>
                            <CIcon name="cil-search"/>
                        </CInputGroupText>
                        <CFormInput v-model.lazy="options.search" :placeholder="$t('Search')" :aria-label="$t('Search')"
                                    aria-describedby="search-users" @keyup.enter="fetchData"
                                    @input="handleSearchInputChange" @blur="handleSearchInputBlur"/>
                    </CInputGroup>

                    <CDropdown :key="`${selectedUsers.length}`" color="primary" class="mb-2 ms-1">
                        <CDropdownToggle color="primary" variant="outline" :disabled="!selectedUsers.length">
                            {{ $t('Bulk Action') }}
                        </CDropdownToggle>
                        <CDropdownMenu>
                            <CDropdownItem @click="handleSendEmail()">
                                {{ $t('Send Communication') }}
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                </div>
                <SmartTable :items="users" :items-per-page="parseInt(options.per_page)" :columns="fields"
                            :sorter-value="sort" :selectable="'id'" :selected-items="selectedUsers"
                            :loading="loadingTable" :active-page="options.page" :per-page="options.per_page"
                            :pages="options.last_page"
                            :clear-text=" options.search.length ? 'No search results found' : 'No Data yet'"
                            @sorter-change="changeSort" @selected-items-change="(s) => (selectedUsers = s)"
                            @update-active-page="changePage" @update-per-page="changePerPage">
                    <template #button="{ item }">
                        <router-link :to="''" class="text-decoration-none" @click="toEdit(item)">
                            <span><CIcon icon="cil-pencil"/></span>
                        </router-link>
                        <button class="btn btn-sm btn-ghost-light" @click="handleUserArchiveAttempt(item)">
                            <span v-if="userDeletingId === item.id"
                                  class="btn-spinner c-loading-button-spinner spinner-border spinner-border-sm text-danger">
                            </span>
                            <CIcon v-else icon="cil-trash" class="text-danger" style="cursor: pointer"/>
                        </button>
                    </template>
                </SmartTable>

                <confirmation-modal ref="archiveUserConfirmation"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import {parseError} from '@/utils/api'
import ApiService from '@/services/ApiService'
import {debounce} from '@/utils/debounce'
import Gate from '@/services/Gate/Gate'
import {notifyByPlan, permissionError} from '@/services/Notify/Toasts'
import Navigation from '@/components/Card/Navigation'
import {OPEN_TASKS} from "@/domain/Entities/Task/taskStatuses";

export default {
    name: 'CompanyUsersIndex',
    components: {
        Navigation,
        ConfirmationModal,
    },
    inject: ['toast'],
    data() {
        return {
            mountedComponent: false,
            key: 0,
            selectedUsers: [],
            userDeletingId: false,

            users: [],
            options: {
                per_page: 10,
                page: 1,
                last_page: 1,
                search: '',
                sort_direction: 'desc',
                sort_field: 'created_at',
            },
            sort: {column: 'created_at', state: 'desc'},
            loading: true,
        }
    },
    computed: {
        tabsLink() {
            return [
                {
                    name: 'Employees',
                    route: {
                        name: 'SettingCompanyUsersIndex',
                    },
                },
                {
                    name: 'Invites',
                    route: {
                        name: 'InvitedEmployees',
                    },
                },
            ]
        },
        user() {
            return this.$store.getters['user']
        },
        fields() {
            let fields = [
                {
                    key: 'full_name',
                    label: this.$t('Full Name'),
                    link: {
                        name: 'CompanyUser',
                        params: {user_id: 'id', id: 'company_id'},
                    },
                },
                {key: 'job_title', label: this.$t('Job')},
                {key: 'company_office_name', label: this.$t('Office')},
                {key: 'role', label: this.$t('User Role')},
                {
                    key: 'projects_count',
                    label: this.$t('Projects'),
                    link: {
                        name: 'CompanyUserProjects',
                        params: {user_id: 'id', id: 'company_id'},
                    },
                    format: (v) => `${v || 0}`,
                },
                {
                    key: 'tasks_count',
                    label: this.$t('Open Tasks'),
                    link: {
                        name: 'CompanyUserTasks',
                        params: {user_id: 'id', id: 'company_id'},
                        query: {status: OPEN_TASKS},
                    },
                    format: (v) => `${v || 0}`,
                },
                {
                    key: 'overdue_tasks_count',
                    label: this.$t('Overdue Tasks'),
                    link: {
                        name: 'CompanyUserTasks',
                        params: {user_id: 'id', id: 'company_id'},
                        query: {status: 'Overdue'},
                    },
                    format: (v) => `${v || 0}`,
                },
                {key: 'email', label: 'Email', sorter: false, email: true},
                {key: 'phone', label: 'Phone', sorter: false},
                {key: 'address', label: 'Address', sorter: false},
            ]

            return fields
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true
        })
        this.fetchData()
    },
    methods: {
        async handleSendEmail() {
            await Gate.can('communication', 'employee').then((res) => {
                if (res) {
                    return this.$router.push({
                        name: 'SendCommunication',
                        query: {users: this.selectedUsers},
                    })
                }

                notifyByPlan()
            })
        },
        handleSearchInputBlur(event) {
            return event.target.value || this.fetchData()
        },
        handleSearchInputChange: debounce(function () {
            this.fetchData()
        }, 700),
        async toEdit(item) {
            await Gate.can('edit', 'employee', item.id).then((res) => {
                if (res) {
                    return this.$router.push({
                        name: 'SettingCompanyUsersEdit',
                        params: {id: item.id},
                    })
                }

                this.$notify(permissionError)
            })
        },
        async handleUserArchiveAttempt(user) {
            const check = await Gate.can('edit', 'employee', user.id)
            if (!check) {
                this.$notify(permissionError)
                return false
            }

            if (
                await this.$refs.archiveUserConfirmation.confirm({
                    title: this.$t('Archive user(s)?'),
                    // title: this.$t('Archive user(s)?'),
                    text: this.$t(
                        'Archived user will no longer be able to access the Contracts Connected. Archive users will not be viewed in the system.  You will be able to restore user with all data and accesses trough Archive page.',
                    ),
                    cancelText: this.$t('Cancel'),
                    confirmText: this.$t('Archive'),
                })
            ) {
                this.userArchiveAttempt(user)
            }
        },
        userArchiveAttempt(user) {
            this.userDeletingId = user.id
            ApiService.api.users
                .archiveUser({user_id: user.id})
                .then((res) => {
                    if (res.data.status) {
                        this.toast('info', res.data.message)
                        this.fetchData()
                    } else {
                        this.toast('warning', res.data.message)
                    }
                })
                .catch((err) => {
                    err = parseError(err)
                    if (err.status === 422) {
                        this.validationErrors = err.validationMessages
                    } else {
                        this.toast('warning', err.message)
                    }
                })
                .finally(() => {
                    this.userDeletingId = false
                })
        },
        fetchData() {
            this.loading = true
            this.users = []
            this.$http.companies
                .getCompanyAllUsers({params: this.options})
                .then(({data}) => {
                    this.key++
                    this.users = data.data
                    this.options.last_page = data.meta.last_page
                })
                .catch((err) => {
                    err = parseError(err)
                    if (err.status === 422) {
                        this.validator.errors = err.validationMessages
                    } else {
                        this.toast('warning', err.message)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changePage(val) {
            this.options.page = val
            this.fetchData()
        },
        changePerPage(val) {
            this.options.per_page = val
            this.fetchData()
        },
        changeSort(val) {
            this.options.sort_field = val.column
            this.options.sort_direction = val.state
            this.sort = val
            this.fetchData()
        },
        setCheck(value) {
            this.selectedUsers = value.map((i) => i.id)
        },
    },
}
</script>

<style scoped lang="scss">
div > > > .scrollTable th {
    white-space: nowrap;
}

div > > > .scrollTable th span {
    float: none !important;
    margin-left: 5px;
}

div > > > table.table.table-hover.table-striped + div {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
</style>
